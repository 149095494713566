import {createI18n} from 'vue-i18n';
import en from './en/';
import ru from './ru/';
import am from './am/';

const messages = {
    en: en,
    ru: ru,
    am: am,
};

function getLang() {
    return localStorage.getItem('lang') || 'en';
}

let lang = getLang();

const i18n = createI18n({
    locale: lang,
    messages: messages,
});

export default i18n;