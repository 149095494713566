export default  {
    header_title: 'We Do IT',
    header_text: 'We’ve collected some amazing examples of the projects created by L-IT. Get inspired and start planning the perfect IT solutions with us.',
    portfolio_page_title: 'Grow your business with us',
    portfolio_page_text: 'L-IT Armenia are experts building competitive, on demand solutions.  We understand all the sectors we are working on.\n' +
        'Our team of engineers will work hard to understand your goals, and build a platform to accelerate your growth.',
    projects: [
        {
            img: [
                require('@/assets/portfolio/project1/1.jpg'),
                require('@/assets/portfolio/project1/2.jpg'),
                require('@/assets/portfolio/project1/3.jpg')
            ],
            title: 'MaBell',
            description: 'MaBell is a customer experience boosting platform for the hotel industry.'
        },
        {
            img: [
                require('@/assets/portfolio/project2/1.jpg'),
                require('@/assets/portfolio/project2/2.jpg'),

            ],
            title: 'Connecteam',
            description: 'Connecteam is the employee management app that connects everything a business needs from field to office, all in one place. Massive Enterprises, small businesses and every mom-and-pop shop around can use it to manage day-to-day operations, and drive their business forward.'
        },
        {
            img: [
                require('@/assets/portfolio/project3/1.jpg'),
                require('@/assets/portfolio/project3/2.jpg'),
            ],
            title: 'NotaryAssist',
            description:'It helps to track customers, income, expenses, mileage and more. The solution tracks Notary specific data points and tax information, helping professional Notaries maximize their income.'
        },
        {
            img: [
                require('@/assets/portfolio/project4/1.png'),
                require('@/assets/portfolio/project4/2.png'),
            ],
            title: 'SwipedOn',
            description:'The easiest flexible system, to manage visitors, staff, deliveries. '
        },
    ]
}