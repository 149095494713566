export default {
    header_title: 'Мы Строим ИТ',
    header_text: 'Мы собрали несколько впечатляющих примеров проектов, посвященных информационным технологиям, созданных L-IT. Вдохновитесь и начните планировать идеальные ИТ-решения вместе с нами.',
    portfolio_page_title: 'Развивайте свой бизнес вместе с нами',
    portfolio_page_text:'L-IT Armenia является экспертом в создании конкурентоспособных и востребованных решений. Мы понимаем все отрасли, в которых мы работаем.\n' +
        'Наша команда инженеров приложит все усилия, чтобы понять ваши цели и создать платформу для ускорения роста вашего бизнеса',
    projects: [
        {
            img: [
                require('@/assets/portfolio/project1/1.jpg'),
                require('@/assets/portfolio/project1/2.jpg'),
                require('@/assets/portfolio/project1/3.jpg')
            ],
            title: 'MaBell',
            description: 'MaBell — платформа для улучшения клиентского опыта в гостиничном бизнесе.'
        },
        {
            img: [
                require('@/assets/portfolio/project2/1.jpg'),
                require('@/assets/portfolio/project2/2.jpg'),

            ],
            title: 'Connecteam',
            description: 'Connecteam — приложение для управления сотрудниками, которое объединяет все, что нужно бизнесу, от поля до офиса, в одном. Крупные предприятия, малые предприятия и магазины могут использовать приложение чтобы управлять повседневными операциями и развивать свой бизнес.'
        },
        {
            img: [
                require('@/assets/portfolio/project3/1.jpg'),
                require('@/assets/portfolio/project3/2.jpg'),
            ],
            title: 'NotaryAssist',
            description:'Оно помогает отслеживать клиентов, доходы, расходы, пробег и многое другое. Решение отслеживает конкретные данные нотариуса и налоговую информацию, помогая профессиональным нотариусам максимизировать свой доход.'
        },
        {
            img: [
                require('@/assets/portfolio/project4/1.png'),
                require('@/assets/portfolio/project4/2.png'),
            ],
            title: 'SwipedOn',
            description:'Самая простая гибкая система для управления посетителями, персоналом, доставкой.'
        },
    ]
}